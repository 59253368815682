import { ModelSelect, BasicSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import Datepicker from 'vuejs-datepicker';
import Currency from '../../../../admin/currency';
import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
import party from '@/app/components/generalLedger/party';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'advanceSearch',
  components: {
    ModelSelect,
    BasicSelect,
    Datepicker,
    Currency,
    DatePicker,
    party,
    ModulesList
  },
  watch: {
    selectedProject: function() {
      if (this.selectedProject.value) {
        this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
        this.lovId = this.selectedProject.value;
        this.getOtherLovByProjectId();
      }
    },
    // selectedLeagalEntity: function() {
    //   this.getOrganizationList(this.selectedLeagalEntity.value);
    //   this.getProjectList(this.selectedLeagalEntity.value);
    //   this.getCustomer(this.selectedLeagalEntity.value);
    // },
    selectedBillRequest: function() {
      this.getFmsBillTemplate(this.selectedBillRequest.value);
    }
  },
  data() {
    return {
      customerCurrentPage: 1,
      showValueSetModal: false,
      showModuleForm: false,
      parent_value_set_id: null,
      showCustomerModal: false,
      customerTotalRows: null,
      customerName: null,
      customerPerPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      showPartyModal: false,
      showCustomerSiteModal: false,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      openCurrencyModal: false,
      defaultSelect: {
        value: null,
        text: null
      },
      defaultList: [],
      loader: false,
      lovFieldName: '',
      lovId: '',
      keyValue: {
        value: null,
        text: null
      },
      moduleName: null,
      moduleId: null,
      advanceSearchForm: {
        leagalEntity: [
          {
            value: null,
            text: '--select--'
          }
        ],
        orgUnit: [],
        receiptAgainst: [
          {
            value: null,
            text: null
          }
        ],
        billingStatusList: [],
        paymentMode: [
          {
            value: null,
            text: null
          }
        ],
        drawnOn: [
          {
            value: null,
            text: null
          }
        ],
        projectList: [
          {
            value: null,
            text: null
          }
        ],
        sectorList: [
          {
            value: null,
            text: null
          }
        ],
        pocketList: [
          {
            value: null,
            text: null
          }
        ],
        towerList: [
          {
            value: null,
            text: null
          }
        ],
        floorList: [
          {
            value: null,
            text: null
          }
        ],
        unitList: [
          {
            value: null,
            text: null
          }
        ],
        subUnitList: [
          {
            value: null,
            text: null
          }
        ],
        instrumentStatusList: [
          {
            value: null,
            text: null
          }
        ],
        accountNumList: [
          {
            value: null,
            text: null
          }
        ],
        sourceList: []
      },
      trxNum: '',
      selectedReceiptAgainst: {
        value: null,
        text: null
      },
      trxStatus: {
        value: null,
        text: null
      },
      selectedLeagalEntity: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      recoveryType: {
        value: null,
        text: null
      },
      scheduleType: {
        value: null,
        text: null
      },
      selectedOrgUnit: {
        value: null,
        text: null
      },
      selectedPaymentMode: {
        value: null,
        text: null
      },
      receiptNum: '',
      selectedDrawnOn: {
        value: null,
        text: null
      },
      selectedProject: {
        value: null,
        text: null
      },
      selectedSector: {
        value: null,
        text: null
      },
      selectedPocket: {
        value: null,
        text: null
      },
      selectedTower: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      selectedSource: {
        value: null,
        text: null
      },
      billingCycleList: [],
      selectedBillingCycle: {
        value: null,
        text: null
      },
      billRequestList: [],
      // selectedBillRequest: {
      //   value: 0,
      //   text: null
      // },
      selectedBillRequest: null,
      billTemplateList: [],
      selectedBillTemplate: {
        value: 0,
        text: null
      },
      selectedCustomer: {
        name: null,
        customerId: null,
        siteName: null,
        siteId: null
      },
      currency: {
        name: 'Indian Rupee',
        id: 68
      },
      bpDate: null,
      dueDate: [],
      trxDate: null,
      glDate: [],
      billDate: null,
      amountFrom: '',
      amountTo: '',
      customerList: [],
      customerFields: [
        {
          key: 'site_name'
        },
        {
          key: 'customer_site_id',
          class: 'd-none'
        },
        {
          key: 'customer_id',
          class: 'd-none'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'complete_address'
        },
        {
          key: 'address_id',
          class: 'd-none'
        }
      ],
      address: null,
      trxType: {
        text: null,
        value: null
      },
      depVsetParam: null,
      moduleParam: null
    };
  },
  mounted() {
    const advFormData = this.$store.state.receivables.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    } else {
      this.eventBus.$on('getCurrency', getCurrency => {
        this.currency.name = getCurrency.currency;
        this.currency.id = getCurrency.currency_id;
        this.openCurrencyModal = false;
      });
    }
  },
  methods: {
    searchArTrx() {
     if(this.moduleId){
      const payload = {
        le_id: this.selectedLeagalEntity.value,
        ou_id: this.selectedOrgUnit.value,
        source_hdr_id: this.source.value,
        customer_id: this.selectedCustomer.customerId,
        customer_site_id: this.selectedCustomer.siteId,
        module_id: this.moduleId,
        trx_status_vset: this.trxStatus.value,
        trx_num: this.trxNum,
        currency_id: this.currency.id,
        req_num: this.selectedBillRequest,
        trx_amount_from: this.amountFrom,
        trx_amount_to: this.amountTo,
        gl_date_from: commonHelper.formattedDate(this.glDate[0]),
        gl_date_to: commonHelper.formattedDate(this.glDate[1]),
        value_date_from: commonHelper.formattedDate(this.dueDate[0]),
        value_date_to: commonHelper.formattedDate(this.dueDate[1]),
        trx_date: this.trxDate,
        trx_type_dtl_id: this.trxType.value
      };
      this.$emit('advanceSearch', payload);
     } else{
      alert("Please select module first.")
     }
      this.$store.dispatch('receivables/saveAdvSearchFilters', this);
      // this.$store.state.receivables.receivables.adv_search = this;
    },
    openModuleForm(flag) {
      this.showModuleForm = flag;
    },
    // selectedSearchModule(moduleData) {
    //   this.moduleName = moduleData.module_name;
    //   this.moduleId = moduleData.module_id;
    //   this.showModuleForm = false;
    //   this.trxType = {
    //     value: null,
    //     text: null
    //   };
    // },
    selectedModuleData(module) {
      this.moduleName = module.module_name;
      this.moduleId = module.module_id;
      this.trxType = {
        value: null,
        text: null
      };
      this.showModuleForm = false;
    },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    getSelectedCurrency(item) {
      this.currency.name = item.currency_name;
      this.currency.id = item.currency_id;
      this.openCurrencyModal = false;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getCustomer(leId) {
      const payload = {
        _page: this.customerCurrentPage - 1,
        _limit: this.customerPerPage,
        le_id: leId ? leId : null,
        customer_name: this.customerName
      };
      this.loader = true;
      this.$store.dispatch('fms/getCustomerDetail', payload).then(response => {
        if (response.status === 200) {
          this.loader = false;
          const result = response.data.data;
          if (result.page && result.page.length > 0) {
            this.customerList = result.page;
            this.customerTotalRows = result.total_page;
          }
        }
      });
    },
    selectedCustomerRow(item) {
      this.selectedCustomer.name = item.customer_name;
      this.selectedCustomer.customerId = item.customer_id;
      this.selectedCustomer.siteName = item.site_name;
      this.selectedCustomer.siteId = item.customer_site_id;
      this.showCustomerModal = false;
    },
    openCustomerModal(flag) {
      this.showCustomerModal = flag;
    },

    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.getCustomerByPartyId(item.party_id);
      this.selectedCustomer.name = item.party_name;
      this.showPartyModal = false;
    },
    getCustomerByPartyId(partyId) {
      this.loader = true;
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.selectedCustomer.customerId = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.selectedCustomer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customerId: this.selectedCustomer.customerId
      };
      this.$store
        .dispatch('party/getCustomerSiteById', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.selectedCustomer.siteName = item.site_name;
      this.selectedCustomer.siteId = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    clearForm() {
      const defaultSelecteValue = {
        value: null,
        text: null
      };
      this.selectedCustomer.customerId = null;
      this.selectedCustomer.siteId = null;
      this.selectedCustomer.name = null;
      this.selectedCustomer.siteName = null;
      this.moduleId = null;
      this.moduleName = null;
      this.trxStatus = {
        value: null,
        text: null
      };
      this.trxNum = null;
      this.currency.id = null;
      this.currency.name = null;
      this.amountFrom = null;
      this.amountTo = null;
      this.glDate = [];
      this.dueDate = [];
      this.trxDate = null;
      this.trxType = {
        value: null,
        text: null
      };
      this.bpDate = null;
      this.billDate = null;
      this.selectedLeagalEntity = {
        value: null,
        text: null
      };
      this.selectedOrgUnit = {
        value: null,
        text: null
      };
      this.source = {
        value: null,
        text: null
      };
      this.selectedBillingCycle = defaultSelecteValue;
      this.selectedFloor = defaultSelecteValue;
      this.selectedPocket = defaultSelecteValue;
      this.selectedProject = defaultSelecteValue;
      this.selectedBillRequest = null;
      this.selectedSector = defaultSelecteValue;
      this.selectedSource = defaultSelecteValue;
      this.selectedBillTemplate = defaultSelecteValue;
      this.selectedTower = defaultSelecteValue;
      this.selectedUnit = defaultSelecteValue;
      this.selectedSubUnit = defaultSelecteValue;
      this.$store.dispatch('receivables/saveAdvSearchFilters', null);
      // this.$store.state.receivables.receivables.adv_search = null;
    },
    isModuleParamAvail(){
      if(this.selectedLeagalEntity.value && this.selectedOrgUnit.value && this.moduleId)
      {
        this.openValueSetModal('SOURCE_LE_OU_MOD');
      } else {
        alert('Please Select Legal Entity, Organization Unit & Module');
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      this.moduleParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.selectedLeagalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.selectedProject.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_LE_OU_MOD) {
        this.parent_value_set_id = this.selectedLeagalEntity.value;
        this.depVsetParam = this.selectedOrgUnit.value;
        this.moduleParam = this.moduleId;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.parent_value_set_id = this.selectedTower.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.parent_value_set_id = this.selectedFloor.value;
      } else if (this.vsetCode === 'LEASE SCHEDULE TYPE') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.parent_value_set_id = this.moduleId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLeagalEntity.text = item.value_code;
        this.selectedLeagalEntity.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.selectedOrgUnit.text = item.value_code;
        this.selectedOrgUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SOURCE_LE_OU_MOD) {
        this.source.text = item.value_code;
        this.source.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.selectedProject.text = item.value_code;
        this.selectedProject.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.selectedTower.text = item.value_code;
        this.selectedTower.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.selectedFloor.text = item.value_code;
        this.selectedFloor.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.selectedUnit.text = item.value_code;
        this.selectedUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === 'LEASE SCHEDULE TYPE') {
        this.scheduleType.text = item.value_meaning;
        this.scheduleType.value = item.value_code;
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      } else if (this.vsetCode === 'TRX_VALIDATION') {
        this.trxStatus.text = item.value_meaning;
        this.trxStatus.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TRX_TYPE) {
        this.trxType = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    setSearchItems(item) {
      this.selectedLeagalEntity = item.selectedLeagalEntity;
      this.selectedOrgUnit = item.selectedOrgUnit;
      this.source = item.source;
      this.selectedCustomer.name = item.selectedCustomer.name;
      this.selectedCustomer.siteName = item.selectedCustomer.siteName;
      this.moduleName = item.moduleName;
      this.moduleId = item.moduleId;
      this.trxStatus = item.trxStatus;
      this.trxNum = item.trxNum;
      this.currency = item.currency;
      this.selectedBillRequest = item.selectedBillRequest;
      this.amountFrom = item.amountFrom;
      this.amountTo = item.amountTo;
      this.glDate = item.glDate;
      this.dueDate = item.dueDate;
      this.trxDate = item.trxDate;
      this.trxType = item.trxType;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.selectedLeagalEntity.value) {
        this.selectedLeagalEntity = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.selectedOrgUnit.value) {
        this.selectedOrgUnit = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.source.value) {
        this.source = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.trxType.value) {
        this.trxType = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.trxStatus.value) {
        this.trxStatus = {
          value: null,
          text: null
        };
      }
    }
  },
  destroyed() {
    this.eventBus.$off('getCurrency');
  }
};
